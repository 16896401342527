export const ArrowOut = (props) => { return (
    <svg width="100%" height="auto" viewBox="0 0 84 123" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_7_847)">
            <path d="M41.9999 0L78.373 63C93.769 89.6667 74.524 123 43.732 123H40.2679C9.47588 123 -9.76915 89.6667 5.62685 63L41.9999 0Z" fill={props.color}/>
            <path d="M41.9999 0L78.373 63C93.769 89.6667 74.524 123 43.732 123H40.2679C9.47588 123 -9.76915 89.6667 5.62685 63L41.9999 0Z" fill={props.color}/>
            <path d="M41.9999 0L78.373 63C93.769 89.6667 74.524 123 43.732 123H40.2679C9.47588 123 -9.76915 89.6667 5.62685 63L41.9999 0Z" fill={props.color}/>
        </g>
        <defs>
            <filter id="filter0_i_7_847" x="0.208252" y="0" width="100%" height="auto" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.5975 0 0 0 0 0.425 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7_847"/>
            </filter>
        </defs>
    </svg>
)}