import { ArrowMain } from "./arrow-main";
import { ArrowOut } from "./arrow-out";
import { ArrowIn } from "./arrow-in";

const Arrow = (props) => {
    return (
        <div className="arrow-container">
            <ArrowOut color={props.color ?? '#FF9922'} />
            <ArrowMain color={props.color ?? '#FF9922'} />
            <ArrowIn color={props.color ?? '#FF9922'} />
        </div>
    );
}

export default Arrow