export const ArrowIn = (props) => {return (
    <svg width="calc(100% - 18px)" height="auto" viewBox="0 0 60 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_7_849)">
            <path d="M30 0L55.4274 43.8747C66.7822 63.4673 52.6451 88 30 88C7.35487 88 -6.78219 63.4673 4.57261 43.8747L30 0Z" fill={props.color}/>
            <path d="M30 0L55.4274 43.8747C66.7822 63.4673 52.6451 88 30 88C7.35487 88 -6.78219 63.4673 4.57261 43.8747L30 0Z" fill={props.color}/>
            <path d="M30 0L55.4274 43.8747C66.7822 63.4673 52.6451 88 30 88C7.35487 88 -6.78219 63.4673 4.57261 43.8747L30 0Z" fill={props.color}/>
            <path d="M30 0L55.4274 43.8747C66.7822 63.4673 52.6451 88 30 88C7.35487 88 -6.78219 63.4673 4.57261 43.8747L30 0Z" fill="url(#paint0_linear_7_849)" fillOpacity="0.2"/>
        </g>
        <defs>
            <filter id="filter0_i_7_849" x="0.567139" y="0" width="58.8657" height="99" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="17"/>
                <feGaussianBlur stdDeviation="5.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.670833 0 0 0 0 0.245078 0 0 0 0 0.00559026 0 0 0 0.85 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7_849"/>
            </filter>
            <linearGradient id="paint0_linear_7_849" x1="30" y1="0" x2="30" y2="88" gradientUnits="userSpaceOnUse">
                <stop stopColor={props.color}/>
                <stop offset="0.713542" stopColor={props.color}/>
            </linearGradient>
        </defs>
    </svg>
)}

