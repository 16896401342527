export const ArrowMain = (props) => {return (
    <svg width="calc(100% - 6px)" height="auto" viewBox="0 0 78 114" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5" filter="url(#filter0_i_7_848)">
            <path d="M39 0L71.9316 56.8819C86.6185 82.2502 68.313 114 39 114C9.68695 114 -8.6185 82.2501 6.0684 56.8818L39 0Z" fill={props.color}/>
            <path d="M39 0L71.9316 56.8819C86.6185 82.2502 68.313 114 39 114C9.68695 114 -8.6185 82.2501 6.0684 56.8818L39 0Z" fill={props.color}/>
            <path d="M39 0L71.9316 56.8819C86.6185 82.2502 68.313 114 39 114C9.68695 114 -8.6185 82.2501 6.0684 56.8818L39 0Z" fill={props.color}/>
            <path d="M39 0L71.9316 56.8819C86.6185 82.2502 68.313 114 39 114C9.68695 114 -8.6185 82.2501 6.0684 56.8818L39 0Z" fill="url(#paint0_linear_7_848)" fillOpacity="0.2"/>
        </g>
        <defs>
            <filter id="filter0_i_7_848" x="0.890625" y="0" width="76.2188" height="125" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="17"/>
                <feGaussianBlur stdDeviation="5.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.670833 0 0 0 0 0.245078 0 0 0 0 0.00559026 0 0 0 0.85 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7_848"/>
            </filter>
            <linearGradient id="paint0_linear_7_848" x1="39" y1="0" x2="39" y2="114" gradientUnits="userSpaceOnUse">
                <stop stopColor={props.color}/>
                <stop offset="0.713542" stopColor={props.color}/>
            </linearGradient>
        </defs>
    </svg>
)}
