import React, { createContext, useState, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';

export const SocketContext = createContext();
export const useSocket = () => useContext(SocketContext)
const socket = io('wss://socket.asimthecat.com');
// const socket = io('ws://localhost:3030');

const ContextProvider = ({ children }) => {
  const [slices, setSlices] = useState([]);
  const [colors, setColors] = useState([]);

  const handleSpinWheel = () => {
    socket.emit('spin');
  }

  const handleInitial = ({slices, colors}) => {
    console.log('handleInitial', slices, colors)
    setSlices(slices)
    setColors(colors)
  }

  useEffect(() => {
    socket.emit('connected');
    socket.on('connected', handleInitial);

  }, [])

  return (
    <SocketContext.Provider value={{
        socket,
        slices,
        colors,
        handleSpinWheel
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider };
