import React, { useEffect, useState } from 'react';
import '../assets/scss/style.scss';
import Arrow from '../components/arrow';
import { sleep } from '../components/utils';
import { useSocket } from '../context/socket-context';

const Home = () => {
  const [degree, setDegree] = useState(0);
  const [prize, setPrize] = useState('');
  const [win, setWin] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const { socket, handleSpinWheel, slices, colors } = useSocket();
  const slicesReverse = [...slices].reverse()

  const spin = async () => {
    handleSpinWheel()
    await setDegree(0);
    await setSpinning(true)
  }

  const handleAnimation = async (index) => {
    if(slices.length > 0){
      let mid = (180 / slices.length);
      let time = Math.floor(Math.random()*10 + index)*360;
      setDegree(time*index + mid*index*2 + 90 - mid*Math.random());
      await sleep(3500)
      handleColors('win-color', slices[index].color);
      setPrize(slices[index].prize);
      await sleep(1500)
      setWin(true)
      setSpinning(false)
    }
  }
  
  const handleClose = () => {
    setWin(false);
    handleColors('win-color', 'var(--white)');
  }

  const handleColors = (name, color) => {
    document.documentElement.style.setProperty(`--${name}`, color);
  }

  useEffect(() => {
    socket.on("winner", ({index}) => handleAnimation(index))
    colors.map(({color, hex}) => {
      handleColors(color, hex)
    })
  }, [slices, colors])

  return (
      <div className='table'>
        <div className='wheel'>
          <div className='border'>
            <div className='lights'>
              {slices.map((piece, index) => {
                let offset = index*(360/slices.length);
                let angle = offset*(Math.PI/180);
                let cos = Math.cos(angle);
                let sin = Math.sin(angle);
                return  <span 
                          key={`light-${index}`}
                          style={{
                            left: `${(sin + 1)*48 + 1}%`,
                            top: `${(cos + 1)*48 + 1}%`,
                          }}
                        >
                        </span>
              })}
            </div>
            <div className='border-in'>
              <div className='pie-out'>
                <div className='pie'>
                  <svg width="100%" height="100%" viewBox={`0 0 64 64`} style={{transform: `rotate(${degree}deg)`}}>
                    {slices.map((piece, index) => {
                      let offset = index*(360/slices.length);
                      let angle = offset*(Math.PI/180);
                      let factor = 100 / slices.length;
                      let cos = Math.cos(angle+6);
                      let sin = Math.sin(angle+6);
                      return  <g key={`piece-${index}`}>
                                <circle 
                                  cx={32+cos*1.4}
                                  cy={32-sin*1.4}
                                  r="15.91549430918954" 
                                  fill="transparent"
                                  stroke={`${piece.color}`} 
                                  strokeWidth={`${29}`} 
                                  strokeDasharray={`${factor}, ${100 - factor}`} 
                                  strokeDashoffset={`${index*factor}`}
                                >
                                </circle>
                              </g>
                    })}
                    {slicesReverse.map((piece, index) => {
                      let offset = 360/slices.length;
                      return  <text 
                                key={`text-${index}`}
                                fill="#fff"
                                x={'3%'}
                                dominantBaseline="middle"
                                style={{
                                  transform: `translate(0%, 50%) rotate(${offset*(index - (slices.length - 3)/2)}deg)`,
                                  transformOrigin: `${50}% ${0}%`
                                }}
                              >
                                {`${piece.prize}`}
                              </text>
                            })}
                  </svg>
                  <div className='center-out'>
                    <div className='center'>
                      <div className='center-in'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='arrow'>
            <Arrow color={'#FF9922'} />
          </div>
        </div>
        <button type="button" className="spin-button" onClick={spin} disabled={spinning}>
          ÇEVİR
        </button>
        <div className='bottom'>
          <div className='bottom-side'>
            <div className='side-card'>
              <span className='title'>Jeton</span>
              <span className='value token'>100</span>
            </div>
            <div className='side-card'>
              <span className='title'>Bahis</span>
              <span className='value bet'>20</span>
            </div>
          </div>
          <div className='bottom-side'>
            <div className='side-card'>
              <span className='title'>Son Kazanç</span>
              <span className='value'>20</span>
            </div>
            <div className='side-card'>
              <span className='title'>Kazanç</span>
              <span className='value'>20</span>
            </div>
          </div>
        </div>
        <div className={`winner-container ${win ? 'show' : ''}`}>
          <div className='winner'>
            <a role="button" onClick={handleClose} className='exit'></a>
            <div className='header'>Tebrikler!</div>
            <div className='content'>Kazanç</div>
            <div className='footer'>{prize}</div>
          </div>
        </div>
      </div>
  );

}

export default Home;
